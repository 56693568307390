import React, {useEffect} from "react"
import { graphql } from "gatsby"

const colorize = (color) => {

  // Variables for red, green, blue values
  let r, g, b, hsp;
  
  color = +("0x" + color.slice(1).replace( 
    color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
  0.299 * (r * r) +
  0.587 * (g * g) +
  0.114 * (b * b)
  );

  if (hsp > 127.5) {
    return ["black", "rgba(255,255,255,0.3)"]
  } else {
    return ["white", "rgba(0,0,0,0.3)"]
  }
}

const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(' ')
  let line = ''

  context.shadowOffsetX = 3
  context.shadowOffsetY = 3
  // context.fillText("No shadow", 90, 80);

  for(let n = 0; n < words.length; n++) {
    let testLine = line + words[n] + ' '
    let metrics = context.measureText(testLine)
    let testWidth = metrics.width
    if (testWidth > maxWidth && n > 0) {
      context.shadowColor = "rgba(0,0,0,0.3)"
      context.fillText(line, x, y)
      line = words[n] + ' '
      y += lineHeight
    }
    else {
      line = testLine
    }
  }
  context.fillText(line, x, y)
}

export default function IG({ data }) {

  const images = data.allPexelsPhoto.edges
  const ig = images[Math.floor(Math.random() * images.length)].node

  useEffect(() => {

    const quote = data.allAirtable.nodes[0].data.Content

    const c = document.getElementById("canvas")
    const ctx = c.getContext("2d")
    const colors = colorize(ig.avg_color)
    
    let img = new Image()
    img.crossOrigin = "Anonumous"
    img.onload = () => {
      ctx.drawImage(img, -240, 0, 1562, 1080)
      // ctx.drawImage(img, 0, 0)
      ctx.fillStyle = colors[1]
      ctx.fillRect(30, 30, 1020, 1020);
      ctx.textBaseline = 'top'
      ctx.font = "bold 80px sans-serif"
      ctx.fillStyle = colors[0]
      wrapText(ctx, quote, 80, 80, c.width - 240, 100)
    }

    img.src = ig.src.large

    // const download = document.getElementById("download")
    
    // localStorage.setItem("asset", c.toDataURL("image/jpeg", 0.6))
    // download.href = localStorage.getItem("asset")

  }, [])

  return (
    <>
      {/* <a href="/" id="download" download> */}
        <canvas id="canvas" width="1080" height="1080"></canvas>
      {/* </a> */}
      Photo by <a href={ig.url}>{ig.photographer}</a>
    </>
  )
}

export const pexels = graphql`
query queryPexels {
  allPexelsPhoto(limit: 30) {
    edges {
      node {
        avg_color
        url
        photographer
        src {
          large
        }
      }
    }
  }

  allAirtable(limit: 1, sort: {fields: data___Date, order: DESC}) {
    nodes {
      data {
        Content
      }
    }
  }
}
`


